import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialogue from './Dialogue'
import styles from './styles.module.scss'
import { currentUser, UserData } from '../../../logic/Backend'
import { showErrorToast } from '../../../logic/Toaster'

export type ChatState = {
  chatMessagesState: ChatType[]
}

export type ChatType = {
  message: string
  sender: string
  sessionId?: string
}

type chatLoadingState = {
  chatLoadingState: boolean
}

const apiUrl = `${process.env.REACT_APP_SEARCH_BAR_API}?message=`

const Chat = () => {
  const dispatch = useDispatch()
  const latestMessageRef = useRef<null | HTMLDivElement>(null)
  const [user, setUser] = useState<UserData>()
  const chatHistory = useSelector((state: ChatState) => state.chatMessagesState)
  const loadingState = useSelector((state: chatLoadingState) => state.chatLoadingState)
  useEffect(() => {
    const getCurrentUser = async () => {
      setUser(await currentUser())
    }
    getCurrentUser()
  }, [])
  const scrollToBottom = () => {
    latestMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const [value, setValue] = useState('')

  const onValueChange = (inputText: string) => {
    setValue(inputText)
  }

  const onSendMessage = (message: string) => {
    sendMessage(message)
  }
  const onSuggestedConversationClick = (message: string) => {
    sendMessage(message)
  }

  const sendMessage = async (message: string) => {
    dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: message, sender: 'user' } })
    dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: true })
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, user_id: user.id, session_id: chatHistory[2]?.sessionId }),
      })
      const res = await response.json()
      dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: res.answer, sender: 'ai', sessionId: res.session_id } })
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
    } catch (error) {
      showErrorToast('Error sending message')
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
      return
    }
    return
  }
  useEffect(() => {
    scrollToBottom()
  }, [chatHistory.length])
  return (
    <div className={styles.chatPageContainer}>
      <Dialogue
        chatHistory={chatHistory}
        onValueChange={onValueChange}
        onSendMessage={onSendMessage}
        value={value}
        loading={loadingState}
        onSuggestedConversationClick={onSuggestedConversationClick}
      />
      <div className={styles.chatHistoryContainer}>Chat History</div>
    </div>
  )
}
export default Chat
