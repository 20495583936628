import { createModel } from '@rematch/core'
import { RootModel } from '.'

const chatMessagesState = createModel<RootModel>()({
  state: [{ message: '', sender: '', sessionId: '' }],
  reducers: {
    updateChatHistory(state, payload) {
      return [...state, { message: payload.message, sender: payload.sender, sessionId: payload.sessionId }]
    },
  },
})

export default chatMessagesState
